import React from 'react'
import { navigate } from 'gatsby'

import Fade from 'react-reveal/Fade'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Button from '../components/button'
import RoomDescGallery from '../components/roomDescGallery'
import RoomDescSimge from '../components/roomDescSimple'

import ImageIntersection from '../images/intersecci-n-6.svg'
import ImageIntersection2 from '../images/intersecci-n-8.svg'

import ImageRoom1 from '../images/captura-de-pantalla-2020-01-22-a-la-s-13-33-40.png'
import ImageRoom2 from '../images/captura-de-pantalla-2020-01-22-a-la-s-13-33-40@2x.png'
import ImageRoom3 from '../images/captura-de-pantalla-2020-01-22-a-la-s-13-33-40@3x.png'

import ImageRoomExtra1 from '../images/captura-de-pantalla-2020-01-22-a-la-s-13-33-50.png'
import ImageRoomExtra2 from '../images/captura-de-pantalla-2020-01-22-a-la-s-13-33-50@2x.png'
import ImageRoomExtra3 from '../images/captura-de-pantalla-2020-01-22-a-la-s-13-33-50@3x.png'

import { TENT } from '../constants'

const RoomsDetails = () => {
  const goToHostingPage = () => {
    navigate('/hospedaje')
  }

  return (
    <Layout
      gradient={{
        background: 'linear-gradient(to bottom, #202945, rgba(32, 41, 69, 0))',
      }}
    >
      <SEO title="Nido" />
      <div className="rooms-details__main-image rooms-details__main-image--nido">
        <header>
          <Fade top duration={2000}>
            <h1 className="main-image__header">Nido Samadhi</h1>
            <h2 className="main-image__header main-image__header--smaller">
              of Careyes
            </h2>
          </Fade>
        </header>
        <div className="main-image__description">
          <Fade duration={3000} delay={300}>
            <p>
              Samadhi Nest is made up of 19 spectacularly beautiful and cozy
              Mongolian Yurts. Each Yurt has its own unique color, name,
              experience, and stunning view. Each private bathroom delivers an
              authentic and natural way to experience the wilderness of our
              location. They are surrounded by palm tress, flowers, plants,
              bamboo, and an open air roof, providing a breathtaking sky view.
              Furthermore, to fully immerse ourselves in the nature and to be in
              complete harmony with the environment, all the water from the
              sinks, shower, and etc… gets delivered through an eco-friendly
              irrigation system that waters all our plants. Therefore, we
              provide each of our guests biodegradable products (shampoo,
              conditioner, and soap) to protect and nurture our environment.
              Guests may choose between a king size bed or two single beds.
            </p>
          </Fade>
        </div>
        <Button onClick={goToHostingPage}>REGRESAR</Button>
      </div>
      <section className="rooms-details__content">
        <Fade duration={2000}>
          <RoomDescGallery {...TENT} />
        </Fade>
      </section>
      <section className="rooms-details__description rooms-details__description--nido">
        <header>
          <h1 className="description__header">Samadhi Camp has a large</h1>
          <h3 className="description__subheader">reception area and lounge</h3>
        </header>
        <p className="description__text">
          Shared yurts accomodate groups of 2 and 4 in comfort. The package
          prices listed are per person and include 5 days and 4 nightsin these
          gorgeous 2 person Mogolian yurts as well as one ticket, acces to all
          Ondalina events, dinners &amp; drinks at each of 4 nights, and
          shuttles to go around day and night. Breakfast will also be included
          in your ticket price
        </p>
      </section>
      <section>
        <RoomDescSimge
          mainImage={[ImageRoom1, ImageRoom2, ImageRoom3]}
          roomImage={[ImageRoomExtra1, ImageRoomExtra2, ImageRoomExtra3]}
        />
      </section>
      <section className="rooms-details__footer">
        <p className="footer__section">
          Maecenas sodales laoreet dui vitae imperdiet. Vestibulum iaculis sem
          eu nulla vulputate, vestibulum finibus nisi volutpat. Cras finibus
          augue vel ex posuere, sit amet fermentum diam imperdiet. In et nibh
          ex. Sed faucibus arcu eu hendrerit vestibulum. Morbi eget turpis sed
          purus porta volutpat. Nam sit amet tincidunt magna.
        </p>
        <img
          src={ImageIntersection2}
          alt="decorator"
          className="nido-section__image-decorator-2"
        />
      </section>
      <img
        src={ImageIntersection}
        alt="decorator"
        className="nido-section__image-decorator"
      />
    </Layout>
  )
}

export default RoomsDetails
